.rsvp-container {
    height: 65%;
}

.rsvp {
    font-size: 25px;
    height: 100%;
    width: 100%;
}

.rsvp-response {
    font-size: 30px;
}

.rsvp-response-table {
    width: 100%;
}

.rsvp-response-table td {
    width: 30%;
}

.rsvp-response-table tr {
    width: 30%;
}

.rsvp-response-button-container {
    position: relative;
}

.rsvp-response-button {
    background-color: inherit;
    border-color: #c0bcbc;
    cursor: pointer;
    color: #88884a;
    font-weight: bolder;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 50%;
}

.rsvp-response-button.active {
    background-color: #274854;
}

.rsvp-response-diet {
    text-align: center;
    background-color: inherit;
    color: #274854;
    margin-top: 35px;
}

.rsvp-response-submit-button {
    margin-top: 20px;
    font-family: inherit;
    font-size: inherit;
    color: #274854;
    background-color: #edf0f1;
    border: none;
    cursor: pointer;
    box-shadow: inset 1px 1px 1px rgba(232, 225, 225, 0.8), inset -3px -3px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 1px 1px 1px rgba(232, 225, 225, 0.8), inset -3px -3px 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1), inset -3px -3px 6px rgba(0, 0, 0, 0.3);
}

.rsvp-response-submit-button:hover {
    background-color: #e1f0f5;
}

.rsvp-response-heading {
    font-family: Georgia, serif;
    font-size: 13px;
}

.rsvp-invite-names {
    font-family: Georgia, serif;
    font-size: 18px;
}

.rsvp-response-details {
    font-size: 20px;
}

.rsvp-info-route a {
    text-decoration: none;
}