.wedding-container {
  text-align: center;
  background-color: #f8f8f1;
  width: 100%;
  height: 100%;
  position: fixed;
  font-family: 'Brush Script MT', cursive;
  color: #274854;
  overflow-y: scroll;
}
